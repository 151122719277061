import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import AssignPartner from "../components/modals/AssignPartner";
const MechantsRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [assign, setAssign] = useState({})
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const handleAssign = (data) => {
    setAssign(data)
    setIsOpen(true)
  };

  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "fullname",
      headerName: "Partner Name",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return <div>{params.row.merchant.fullname || "N/A"}</div>;
      },
    },

    {
      field: "Location",
      headerName: "Location",
      type: "string",
      flex: 3,
      renderCell: (params) => {
        return <div>{params.row.location || "N/A"}</div>;
      },
    },
    {
      field: "requestType",
      headerName: "Amount",
      type: "string",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Order Type",
      type: "string",
      flex: 1,
    },
    {
      field: "privileges",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{params.row.isAssigned ? "Assigned" : "Not Assigned"}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Assign",
      type: "actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <button
            onClick={() => handleAssign(params.row)}
            className="px-2 py-1 text-[10px] font-bold rounded-lg bg-zinc-200"
          >
            Assign
          </button>
        );
      },
    },
  ];
  const getMerchantRequestData = async () => {
    setIsLoading(true);
    const response = await api.getMerchantRequest(paginationModel.page);

    const { status, data } = response;
    if (status >= 200 && status < 300) {
      console.log(data, "data");
      setIsLoading(false);
      setRowCountState(data?.count);
      setRows(
        data?.data?.map((item, index) => {
          return { ...item, _id: 8 * paginationModel.page + index + 1 };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getMerchantRequestData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, rowCountState, setRowCountState]);

  return (
    <>
      <div className="w-full mt-6 data-grid">
        <div className="flex justify-between mb-6 items-center">
          <h4>Merchant Request</h4>
          {/* <Link to="/create-subadmin">
          <button className="btn btn-primary">Create New SubAdmins</button>
        </Link> */}
        </div>

        <div className=" h-[600px] w-full">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <AssignPartner
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        data={assign}
      />
    </>
  );
};

export default MechantsRequest;
