import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { sideBarLinks } from "../utils/constants";
import { reactIcons } from "./../utils/icons";
import { toast } from "react-toastify";
import * as api from "../redux/api";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../redux/features/authSlice";
import { renderSidebar } from "./../utils/function";
const Sidebar = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      const res = await api.logout();
      const { data } = res;
      if (data.status === "success") {
        dispatch(setLogout());
        toast.success("Logged Out Successfully");
        navigate("/");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    if (user) {
      setFilterData(renderSidebar(sideBarLinks, user));
    }
  }, [user]);
  console.log(user, "user");
  return (
    <div className="h-screen flex flex-col justify-between bg-white shadow-md shadow-black/10  overflow-auto w-full py-8">
      <div className="flex items-center gap-2 justify-between pr-1">
        <div
          className="cursor-pointer px-2"
          onClick={() => navigate("/dashboard")}
        >
          <img src="./images/logo/logo.png" alt="logo" className="w-[120px]" />
        </div>
        <button
          onClick={() => setIsOpen(true)}
          className="w-10 h-10 flex-center bg-theme-lighter rounded-full text-3xl"
        >
          {reactIcons.arrowleft}
        </button>
      </div>

      <div className="flex flex-col  gap-1  mt-8 xxl:mt-12 flex-1">
        {filterData.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            end
            className={({ isActive }) =>
              `relative font-semibold  py-3  w-full px-4  flex gap-4 items-center cursor-pointer duration-100  ${
                isActive
                  ? "bg-theme-darker  text-white"
                  : "text-black hover:bg-theme-lighter hover:text-black"
              }`
            }
          >
            <span className=" text-xl">{item.icon}</span>{" "}
            <span className="text-[14px] xl:text-[16px]"> {item.title} </span>{" "}
          </NavLink>
        ))}
      </div>

      <div>
        <div
          onClick={handleLogOut}
          className="relative font-semibold text-red-dark py-3  w-full px-4  flex gap-4 items-center cursor-pointer duration-100 hover:bg-red-light"
        >
          <span className="text-xl ">{reactIcons.logout}</span>{" "}
          <span className="text-[14px] xl:text-[16px]"> Logout </span>{" "}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
